import React from 'react';
import SEO from '../components/seo';

const Privacy = ({ location }) => {
    return (
        <article className="page-content page-content">
            <SEO title="Privacy Policy - Mike" url={location.pathname} />
            <header className="text-center">
                <h1>Privacy Policy</h1>
                <p className="page-content__date">Last updated: 21st May 2018</p>
            </header>
            
            <div className="page-content__body">            
                <div className="page-content__inner">
                    <p>
                        LoudUp LTD having a principal place of business at Manor House, Mill Lane, Southampton, Hampshire, United Kingdom, SO16 0YE (hereinafter, referred to as the "Company", "we", "us", or "our") collects from the individual user (hereinafter, referred to as "you" or "your") certain personal data through the website <a href="https://www.mikeapp.co">https://www.mikeapp.co</a> (hereinafter, referred to as "the Website") and the mobile app - Mike (hereinafter, referred to as "the App"). The Website and the App are collectively referred to as "the Service". This Privacy Policy contains Company’s policies and procedures governing the processing of personal data through the Service. The processing of personal data through the Service is done in accordance with the UK Data Protection Act 1998 and the EU General Data Protection Regulation (Regulation (EU) 2016/679) (GDPR).
                    </p>
                    <ol>
                        <li>Personal data collected by the Company
                            <ol>
                                <li>The Service allows you to register an account. When you register an account on the Service, the Company collects the following personal data from you: (1) email address; (2) password; (3) display name; </li>
                                <li>If you sign up for our newsletter by using the "Subscribe to the Mike Mailing List" functionality available on the Website, the Company will collect your email address.</li>
                                <li>When you visit the Website, the Company collects your IP address.</li>
                                <li>If you use Facebook to log in or sign up to the Service, Facebook will provide us with the following personal data: (1) date of birth, (2) email address; (3) gender; (4) a list of your Facebook friends; (5) location (6) name; (7) profile picture; (8) user id; (9) user name; (10) access token; We will add your email address, name, user id, and access token to our database in order to register your user account.</li>
                                <li>If you use Twitter to log in or sign up to the Service, Twitter will provide us with the following personal data: (1) name; (2) screen name; (3) email address; (4) location; (5) profile picture; (6) user id; (7) access token; We will add your email address, name, user id, and access token to our database in order to register your user account.</li>
                            </ol>
                        </li>
                        <li>The purpose of collection of personal data
                            <ol>
                                <li>The personal data collected through the Service is used only for:
                                    <ul>
                                        <li>the purposes for which the data is provided;</li>
                                        <li>customising the Service's content based on your location</li>
                                        <li>providing you with advertisements of products and services which may be of interest to you;</li>
                                        <li>delivering our newsletter;</li>
                                        <li>sending you notifications about updates of the Service; </li>
                                        <li>sending you information about promotions and/or events that may be of interest to you;</li>
                                        <li>analysing how users use the Service</li>
                                        <li>providing support for any issues or errors that may occur</li>
                                        <li>audit and security purposes;</li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li>Non-personal data collected by us
                            <ol>
                                <li>The Company may collect non-personal data, such as browser types, device types, operating systems, and the URL addresses of websites clicked to and from the Website. The Company collects the non-personal data mentioned in this Section 3.1 in order to analyse what kind of users visit and use the Service, how they find it, how long they stay, from which other websites they come to the Website, what pages they look at, and to which other websites they go from the Website.</li>
                                <li>The Company collects data on the actions performed using the Service such as pages viewed, tasks completed, events scheduled, and goals met. The company may at times publish aggregate data on the actions performed using the Service. This data will not contain any information that can identify individuals.</li>
                            </ol>
                        </li>
                        <li>The Google Maps API(s)
                            <ol>
                                <li>The Service uses the Google Maps API(s). By using the Service, you agree that Google Privacy Policy available on <a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a> will apply to your use of Google Maps API(s).</li>
                            </ol>
                        </li>
                        <li>Protection of personal data
                            <ol>
                                <li>The Company employs information security tools to protect personal data collected through the Service. Such information security tools, include, but not limited to, secured networks and encryption.</li>
                            </ol>
                        </li>
                        <li>Third party access to personal data
                            <ol>
                                <li>If you send a support query by using the Website and your query includes personal data (for instance, your first name, last name, phone number and email address) that personal data will be stored and processed by HelpScout. HelpScout provides a platform allowing us to manage and answer your support queries. HelpScout is a trading name of Brightwurks Inc., a USA company. HelpScout complies with the U.S.-EU Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from the Member States of the European Union. HelpScout has certified that it adheres to the Privacy Shield Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement. If you would like to read more information on the U.S.-EU Privacy Shield Framework, please visit <a href="https://www.privacyshield.gov/welcome" target="_blank" rel="noreferrer noopener">https://www.privacyshield.gov/welcome</a> . By including personal data in your support queries submitted through the Website, you consent to the transfer of your personal data outside the European Union. If you would like to read HelpScout’s privacy policy, please visit <a href="http://www.helpscout.net/privacy/" target="_blank" rel="noreferrer noopener">http://www.helpscout.net/privacy/</a> .</li>                     
                                <li>The email address provided by you when signing up for our newsletter or registering an account will be used by MailChimp, which is a trading name of Rocket Science Group, LLC, and Drip, which is owned by LeadPages, to provide you with the requested newsletter. LeadPages is a USA company. MailChimp is a USA company which complies with the U.S.-EU Safe Harbor Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from the Member States of the European Union. MailChimp has certified that it adheres to the Safe Harbor Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement. If you would like to read more information on the U.S.-EU Safe Harbor Framework, please visit <a href="http://www.export.gov/safeharbor/" target="_blank" rel="noreferrer noopener">http://www.export.gov/safeharbor/</a> . By signing up for our newsletter, you consent to the transfer of your personal data outside the European Union. If you would like to read MailChimp's privacy policy, please visit <a href="http://mailchimp.com/legal/privacy/" target="_blank" rel="noreferrer noopener">http://mailchimp.com/legal/privacy/</a> . If you would like to read Drip's privacy policy, please visit <a href="https://www.drip.com/privacy" target="_blank" rel="noreferrer noopener">https://www.drip.com/privacy</a> .</li>
                                <li>The App will send push notifications to your mobile device with details of any activity carried out for your profiles, to update your device and notify you. The notifications may contain personal data such as your name, and will be sent by Microsoft Azure. Microsoft is a USA company. Microsoft complies with the U.S.-EU Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from the Member States of the European Union. Microsoft has certified that it adheres to the Privacy Shield Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement. If you would like to read more information on the U.S.-EU Privacy Shield Framework, please visit <a href="https://www.privacyshield.gov/welcome" target="_blank" rel="noreferrer noopener">https://www.privacyshield.gov/welcome</a> . By installing the App with notifications enabled, you consent to the transfer of your personal data outside the European Union. If you would like to read Microsoft's privacy policy, please visit <a href="https://www.microsoft.com/en-us/privacystatement/OnlineServices/Default.aspx" target="_blank" rel="noreferrer noopener">https://www.microsoft.com/en-us/privacystatement/OnlineServices/Default.aspx</a> </li>
                                <li>We use Mixpanel, Google Analytics, and Microsoft Visual Studio App Center for analytics on the Website and the App to understand how users use the Service. Mixpanel, Google, and Microsoft are USA companies, and comply with the U.S.-EU Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from the Member States of the European Union. Mixpanel, Google, and Microsoft have certified that they adhere to the Privacy Shield Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement. If you would like to read more information on the U.S.-EU Privacy Shield Framework, please visit <a href="https://www.privacyshield.gov/welcome">https://www.privacyshield.gov/welcome</a> . By using the App and the Website, you consent to the transfer of your personal data outside the European Union. You can view the Mixpanel Privacy Policy at <a href="https://mixpanel.com/privacy/" target="_blank" rel="noreferrer noopener">https://mixpanel.com/privacy/</a>, view the Google Privacy Policy at <a href="http://www.google.com/policies/privacy/" target="_blank" rel="noreferrer noopener">http://www.google.com/policies/privacy/</a>, and view the Microsoft Privacy Policy at <a href="https://www.microsoft.com/en-us/privacystatement/OnlineServices/Default.aspx" target="_blank" rel="noreferrer noopener">https://www.microsoft.com/en-us/privacystatement/OnlineServices/Default.aspx</a>.</li>
                                <li>We use Branch Metrics for deep links and attribution data. Branch Metrics is a USA company which complies with the U.S.-EU Safe Harbor Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from the Member States of the European Union. Branch Metrics has certified that it adheres to the Safe Harbor Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement. If you would like to read more information on the U.S.-EU Safe Harbor Framework, please visit <a href="http://www.export.gov/safeharbor/" target="_blank" rel="noreferrer noopener">http://www.export.gov/safeharbor/</a> . By using the App and the Website, you consent to the transfer of your personal data outside the European Union. If you would like to read Branch Metrics' privacy policy, please visit <a href="https://branch.io/policies/#privacy" target="_blank" rel="noreferrer noopener">https://branch.io/policies/#privacy</a> .</li>
                                <li>We may engage in marketing of our products and services using third party platforms such as Google, Facebook, Instagram, and Twitter. Where we wish to target our marketing to users of and visitors to the Website, we may share your personal data with these third party platforms, as well as data such as your activity on the Website. Where any of your data is required for such a purpose, the Company will take all reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights, our obligations, and the obligations of the third party under the law.</li>
                                <li>With the exception of the cases in Sections 6.1, 6.2, 6.3, 6.4, 6.5, 6.6 and 6.7, the Company does not give your personal data to third parties, unless the Company is legally bound to do so.</li>
                            </ol>
                        </li>
                        <li>Accessing and correcting your personal data
                            <ol>
                                <li>If you would like to access, modify, or delete your personal data that is processed through the Service, please send an email to the following email address: hello@mikeapp.co . The Company will answer your request within two weeks.</li>
                            </ol>
                        </li>
                        <li>Retention Period
                            <ol>
                                <li>Your personal data will be kept for as long as is necessary to provide you with the requested service. For instance, if your personal data is collected to deliver the newsletter, your personal data will be kept until you unsubscribe from the newsletter service.</li>
                                <li>When your personal data is no longer necessary to deliver the requested service, the Company will keep your personal data for a maximum of one year in identifiable form.</li>
                            </ol>
                        </li>
                        <li>Your consent
                            <ol>
                                <li>By using the Service, you are consenting to our processing of your personal data as set forth in this Privacy Policy. The term "processing" includes collecting, storing, deleting, using, and disclosing personal data.</li>
                            </ol>
                        </li>
                        <li>Links
                            <ol>
                                <li>The Service may contain links to other websites. The Company is not responsible for the privacy practices of those websites.</li>
                            </ol>
                        </li>
                        <li>Contact details
                            <ol>
                                <li>If you have any questions about this Privacy Policy, please contact the Company by email at <a href="mailto:hello@mikeapp.co">hello@mikeapp.co</a> , or please write to the following address:
                                LoudUp LTD, Manor House, Mill Lane, Southampton, Hampshire, United Kingdom, SO16 0YE</li>
                            </ol>
                        </li>
                        <li>Changes to Our Privacy Policy
                            <ol>
                                <li>We may change this Privacy Policy as we may deem necessary from time to time, or as may be required by law. Any changes will be immediately posted on the Service and you will be deemed to have accepted the terms of the Privacy Policy on your first use of the Service following the alterations. We recommend that you check this page regularly to keep up-to-date.</li>
                            </ol>
                        </li>
                    </ol>                
                </div>
            </div>
        </article>
    );
};

export default Privacy;